import React, { useState } from 'react';
import { FaFacebook, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import logo from '../src/Assets/sad-logo.png'
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {

    const [showMenu, setShowMenu] = useState(false)

    const handleToggleMenu = () => {
        if (showMenu === true) {

            setShowMenu(false)
        }
        else {

            setShowMenu(true)
        }
    }

    const openTwitter = () => {
        window.open('https://twitter.com/', '_blank');
    };

    const openFacebook = () => {
        window.open('https://www.facebook.com/', '_blank');
    };

    const openWhatsapp = () => {
        window.open('https://web.whatsapp.com/', '_blank');
    };

    return (

        <>

            <nav className="navbar">
                <div className='container-fluid'>
                    <div className="navbar-content">
                        <div className="navbar-left">
                            <a href="/" className='navbar-a'>
                                <img src={logo} alt="Logo" className="logo" />
                                Shiromani Akali Dal (Amritsar)
                            </a>
                            <ul className="nav-links mt-3">
                                <li><a href="/">Home</a></li>
                                <li><a href="/idcard-registration">ID Card Registration Form</a></li>
                            </ul>
                        </div>
                        <div className="navbar-right">
                            <FaTwitter className="social-icon" onClick={openTwitter} />
                            <FaFacebook className="social-icon" onClick={openFacebook} />
                            <FaWhatsapp className="social-icon" onClick={openWhatsapp} />
                        </div>
                    </div>
                    <div className="navbar-content2">
                        <div className="navbar-left">
                            <a href="/" >
                                <img src={logo} alt="Logo" className="logo" />
                            </a>
                            <ul className="nav-links mt-3">
                                <li><a href="/" className='fw-bold'>S.A.D (Amritsar)</a></li>
                            </ul>
                        </div>
                        <div className="navbar-right" style={{ float: "right" }}>
                            <GiHamburgerMenu className='hamburger mr-3' onClick={handleToggleMenu} />
                        </div>
                    </div>
                </div>
            </nav>
            {showMenu ?
                <nav className="navbar">
                    <div className="navbar-content2">
                        <div className="navbar-left">
                            <ul className="nav-links2 mt-3">
                                <li><a href="/">Home</a></li>
                                <li className='mt-2'><a href="/registerform">ID Card Registration Form</a></li>
                            </ul>
                        </div>

                    </div>
                </nav>
                :
                null}
        </>
    );
};

export default Navbar;

import React from 'react';
import logo from '../src/Assets/sad-logo.png'
import Footer from './Footer';
import Navbar from './Navbar';

const Home = () => {

  return (
    <>
      <Navbar />
      <div className="main-container">
        <div className="container center-container mb-2">
          <div className="row resp-login" style={{ display: "flex", alignItems: "center" }}>
            <div className='col-md-3 text-center'>
              <img src={logo} alt="Logo" className="home-logo" />
            </div>
            <div className='col text-center'>
              <h1 className="xyz-text">Welcome To <br /> Shiromani Akali Dal Amritsar</h1>
            </div>
          </div>
          <span className='border-bottom mt-3'></span>
          <div className="button-container">
            <h4> Select Registration Type</h4>
            <a href="/idcard-registration" >
              <button className="card-button mt-3">ID Card Registration</button>
            </a>
            <a href="#" >
              <button className="form-button mt-2">Applicant Form</button>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;

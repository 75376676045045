import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import RegisterForm from "./RegisterForm";
import FormDetails from "./FormDetails";

const App = () => {

  return (

    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/idcard-registration" element={<RegisterForm />} />
          <Route exact path="/verifyletter" element={<FormDetails />} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;

import React, { useEffect, useState } from 'react';
import logo from '../src/Assets/sad-logo.png'
import Footer from './Footer';
import Navbar from './Navbar';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const FormDetails = () => {

  const [userDataById, setUserDataById] = useState([])

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  // Fetch User By Id
  useEffect(() => {
    axios.get(`https://backend.sadaamritsar.com/api/users/getById/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }
    })
      .then(response => {
        setUserDataById(response.data.data)
      })
      .catch(error => {
        console.error('Error fetching skills:', error);
      });
  }, []);

  console.log('userDataById[0]?.photo', userDataById[0]?.photo)

  return (
    <>
      <Navbar />
      <div className="main-details-container">
        <div className="center-container">
          <div className="col-12 row resp-login" style={{ display: "flex", alignItems: "center" }}>
            <div className='col-12 col-xl-5 col-lg-5 col-md-12 col-sm-12 text-center'>
              <img src={logo} alt="Logo" className="home-logo" />
            </div>
            <div className='col-12 col-xl-7 col-lg-7 col-md-12 col-sm-12'>
              <h1 className="xyz-text">Shiromani Akali Dal Amritsar Member Details</h1>
            </div>
          </div>
          <span className='border-bottom mt-3'></span>
          <div className="register-form-container">
            <form className='register-form'>
              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Photo:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 ml-3'>
                  <img src={userDataById[0]?.photo} alt="Logo" className="logo2 ml-3" />
                </div>
              </div>

              <span className='border-bottom mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Name:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.full_name}</label>
                </div>
              </div>
              <span className='border-bottom mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Father Name:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.father_husband_name}</label>
                </div>
              </div>
              <span className='border-bottom mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Adhaar Card:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.adhaar_card_no}</label>
                </div>
              </div>
              <span className='border-bottom  mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Country:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.country}</label>
                </div>
              </div>
              <span className='border-bottom  mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>State:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.state}</label>
                </div>
              </div>
              <span className='border-bottom  mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>District:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.district}</label>
                </div>
              </div>
              <span className='border-bottom  mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Address:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.full_address}</label>
                </div>
              </div>
              <span className='border-bottom  mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Phone:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.phone_number}</label>
                </div>
              </div>
              <span className='border-bottom  mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Email</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>{userDataById[0]?.email}</label>
                </div>
              </div>
              <span className='border-bottom  mt-2 mb-2'></span>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 bold-label'>Member Verification Status:</label>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2 semibold-label'>Verified</label>
                </div>
              </div>

              <div style={{ display: 'none' }}>
                <button className="login-button mt-5">Register Now</button>
              </div>

            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FormDetails;

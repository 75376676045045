import React, { useEffect, useState } from 'react';
import logo from '../src/Assets/sad-logo.png'
import { useFormik } from 'formik';
import { GetCountries, GetState } from 'react-country-state-city';
import "react-country-state-city/dist/react-country-state-city.css";
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {

  const MySwal = withReactContent(Swal)

  const navigate = useNavigate()

  const [countries, setCountries] = useState([])
  const [countryid, setCountryid] = useState('')
  const [countryDialCode, setCountryDialCode] = useState('');
  const [stateList, setStateList] = useState([]);

  const formik = useFormik({
    initialValues: {
      full_name: '',
      email: '',
      adhaar_card_no: '',
      father_husband_name: '',
      is_husband: '',
      country: 'India',
      phone_number: '',
      state: 'Punjab',
      district: '',
      village_town: '',
      tehsil: '',
      full_address: '',
      photo: '',
      skills: '',
      has_other_skill: '',
      other_skills: '',
      volunteer_region: '',
    },

    onSubmit: async (data) => {

      const phoneNumber = formik.values.phone_number;
      const defaultDialCode = "+91";
      const finalValue = formik.values.phone_number ? countryDialCode ? `+${countryDialCode}${phoneNumber}` : `${defaultDialCode}${phoneNumber}` : "";

      console.log('data', data)

      data.phone_number = finalValue;

      axios.post('https://backend.sadaamritsar.com/api/users/create', data)
        .then(response => {
          MySwal.fire({
            title: 'Success!',
            text: 'Successfully Registered For ID Card',
            icon: 'success',
            customClass: {
              confirmButton: 'swal-button-right' // Custom class to position the button
            },
            showCancelButton: false, // Hide cancel button
            confirmButtonText: 'OK', // Change button text
            confirmButtonColor: '#7cd1f9'
          }).then(() => {
            navigate('/')
          });
        })
        .catch(error => {
          console.error('Error registering:', error);
          // Handle registration error, e.g., display error message to the user
        });

    }
  });

  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        formik.setFieldValue('photo', base64String);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Maximum Upload Size is 2MB.');
      formik.setFieldValue('photo', '');
    }
  };

  const handleSkillChange = (e) => {
    const skillName = e.target.value;
    const { skills } = formik.values;

    if (e.target.checked) {
      // If the checkbox is checked, add the skill to the selectedSkills array
      const updatedSkills = skills ? `${skills},${skillName}` : skillName;
      formik.setFieldValue("skills", updatedSkills);
    } else {
      // If the checkbox is unchecked, remove the skill from the selectedSkills array
      const updatedSkills = skills.split(',').filter(skill => skill !== skillName).join(',');
      formik.setFieldValue("skills", updatedSkills);
    }
  };

  useEffect(() => {
    GetCountries().then((result) => {
      setCountries(result);
    });
  }, []);

  useEffect(() => {
    GetState(countryid || 101).then((result) => {
      setStateList(result);
    });
  }, [countryid]);

  useEffect(() => {
    if (formik.values.country !== "India") {
      formik.setFieldValue("state", "")
    }
    else if (formik.values.country === "India") {
      formik.setFieldValue("state", "Punjab")
    }
  }, [formik.values.country]);

  return (

    <>
      <Navbar />
      <div className="main-register-container">
        <div className="center-container">
          <div className="col-12 row resp-login" style={{ display: "flex", alignItems: "center" }}>
            <div className='col-12 col-xl-5 col-lg-5 col-md-12 col-sm-12 text-center'>
              <img src={logo} alt="Logo" className="home-logo" />
            </div>
            <div className='col-12 col-xl-7 col-lg-7 col-md-12 col-sm-12'>
              <h1 className="xyz-text">Shiromani Akali Dal Amritsar Member Registration Form</h1>
            </div>
          </div>
          <span className='border-bottom mt-3'></span>

          <div className="register-form-container">

            <form className='register-form' onSubmit={formik.handleSubmit}>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Enter Full Name / ਤੁਹਾਡਾ ਪੂਰਾ ਨਾਮ <span className='label_required'> * </span> </label>
                  <input id='full_name' value={formik.values.full_name} onChange={formik.handleChange} placeholder='Enter Full Name' className='register-input'></input>
                  {/* {getFormErrorMessage('full_name')} */}
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Enter Email / ਤੁਹਾਡੀ ਈ-ਮੇਲ ਆਈਡੀ <span className='label_required'> * </span></label>
                  <input id='email' value={formik.values.email} onChange={formik.handleChange} placeholder='Enter Email' className='register-input'></input>
                </div>
              </div>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Enter Adhaar Card Number / ਤੁਹਾਡਾ ਅਧਾਰ ਕਾਰਡ ਨੰਬਰ <span className='label_required'> * </span></label>
                  <input id='adhaar_card_no' value={formik.values.adhaar_card_no} onChange={formik.handleChange} placeholder='Enter Adhaar Card Number' className='register-input'></input>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Enter Father/Husband Name / ਤੁਹਾਡੇ ਪਿਤਾ / ਪਤੀ ਦਾ ਨਾਮ</label>
                  <input id='father_husband_name' value={formik.values.father_husband_name} onChange={formik.handleChange} placeholder='Enter Father/Husband Name' className='register-input'></input>
                  <label className='mt-1'>
                    <input id='is_husband' value={formik.values.is_husband} onChange={formik.handleChange} type='checkbox' className='register-input ml-2' />
                    <span className='ml-3'> Please Check This Box If Entering Husband Name </span>
                  </label>
                </div>
              </div>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Select Country / ਤੁਹਾਡਾ ਨਿਵਾਸ ਦੇਸ਼ <span className='label_required'> * </span> </label>
                  <select id="country" value={formik.values.country} onChange={(e) => {
                    const selectedCountryName = e.target.value;
                    const selectedCountry = countries.find(country => country.name === selectedCountryName);
                    formik.handleChange(e);
                    setCountryDialCode(selectedCountry?.phone_code);
                    setCountryid(selectedCountry?.id);
                  }} className='register-input'>
                    <option value="">Select a country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Enter Phone Number / ਤੁਹਾਡਾ ਫੋਨ ਨੰਬਰ  <span className='label_required'> * </span> </label>
                  <div style={{ "position": "relative" }}>
                    <input id="phone_number" value={formik.values.phone_number} onChange={formik.handleChange} placeholder="Enter Phone Number" className="register-input" style={{ "paddingLeft": "50px", "width": "100%" }} />
                    <span style={{ "position": "absolute", "top": "50%", "transform": "translateY(-50%)", "left": "0.75rem" }}>{`+${!countryDialCode ? "91" : countryDialCode}`}</span>
                  </div>
                </div>
              </div>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Select State / ਤੁਹਾਡੀ ਸਟੇਟ <span className='label_required'> * </span> </label>
                  {
                    formik.values.country !== "India" ?
                      <input id='state' value={formik.values.state} onChange={formik.handleChange} placeholder='Enter State' className='register-input'></input>
                      :
                      <select
                        id='state'
                        className='register-input'
                        onChange={(e) => {
                          formik.handleChange(e)
                        }}
                        value={formik.values.state}
                      >
                        <option value="">Select a state</option>
                        {stateList.map((state) => (
                          <option key={state?.id} value={state?.name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                  }
                </div>

                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Select District / ਤੁਹਾਡਾ ਜ਼ਿਲ੍ਹਾ <span className='label_required'> * </span> </label>
                  {
                    formik.values.country !== "India" ?
                      <input id='district' value={formik.values.district} onChange={formik.handleChange} placeholder='Enter District' className='register-input'></input>
                      :
                      <select id="district" className='register-input' value={formik.values.district} onChange={formik.handleChange}>
                        <option value="0">Select district</option>
                        <option value="Amritsar">Amritsar</option>
                        <option value="Barnala">Barnala</option>
                        <option value="Bathinda">Bathinda</option>
                        <option value="Faridkot">Faridkot</option>
                        <option value="Fatehgarh Sahib">Fatehgarh Sahib</option>
                        <option value="Fazilka">Fazilka</option>
                        <option value="Ferozpur">Ferozpur</option>
                        <option value="Gurdaspur">Gurdaspur</option>
                        <option value="Hoshiarpur">Hoshiarpur</option>
                        <option value="Jalandhar Rural">Jalandhar Rural</option>
                        <option value="Jalandhar Urban">Jalandhar Urban</option>
                        <option value="Kapurthala">Kapurthala</option>
                        <option value="Ludhiana">Ludhiana</option>
                        <option value="Malerkotla">Malerkotla</option>
                        <option value="Mansa">Mansa</option>
                        <option value="Mansa">Mansa</option>
                        <option value="Moga">Moga</option>
                        <option value="Mohali">Mohali</option>
                        <option value="Mukatsar">Mukatsar</option>
                        <option value="Nawanshehar">Nawanshehar</option>
                        <option value="Pathankot">Pathankot</option>
                        <option value="Patiala Rural">Patiala Rural</option>
                        <option value="Patiala Urban">Patiala Urban</option>
                        <option value="Rupnagar">Rupnagar</option>
                        <option value="Sangrur">Sangrur</option>
                        <option value="Tarn-Taran">Tarn-Taran</option>
                      </select>
                  }
                </div>
              </div>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Enter Village / Town / ਤੁਹਾਡਾ ਪਿੰਡ </label>
                  <input id='village_town' value={formik.values.village_town} onChange={formik.handleChange} placeholder='Enter Village / Town' className='register-input'></input>
                </div>
                <div className='flexColumn col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Enter Tehsil / ਤੁਹਾਡੀ ਤਹਿਸੀਲ </label>
                  <input id='tehsil' value={formik.values.tehsil} onChange={formik.handleChange} placeholder='Enter Tehsil' className='register-input'></input>
                </div>
              </div>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Enter Full Address / ਤੁਹਾਡਾ ਪੂਰਾ ਪਤਾ <span className='label_required'> * </span> </label>
                  <input id='full_address' value={formik.values.full_address} onChange={formik.handleChange} placeholder='Enter Full Address' className='register-input'></input>
                </div>
              </div>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Upload Your Photo / ਤੁਹਾਡੀ ਫੋਟੋ  <span className='label_required'> * </span> </label>
                  <input id='photo' onChange={(e) => {
                    handlePhotoUpload(e)
                    formik.handleChange(e)
                  }} placeholder='Upload Your Photo' type='file' className='register-input'></input>
                </div>
              </div>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Select Skills / Expertise / ਤੁਹਾਡੇ ਹੁਨਰ <span className='label_required'> * </span> </label>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='skills'
                        type='checkbox'
                        className='register-input ml-2'
                        value="Journalism"
                        onChange={handleSkillChange}
                      />
                      <span className='ml-3'> Journalism </span>
                    </label>
                  </div>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='skills'
                        type='checkbox'
                        className='register-input ml-2'
                        value="Public Speaking"
                        onChange={handleSkillChange}
                      />
                      <span className='ml-3'> Public Speaking </span>
                    </label>
                  </div>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='skills'
                        type='checkbox'
                        className='register-input ml-2'
                        value="Social Media Campaign"
                        onChange={handleSkillChange}
                      />
                      <span className='ml-3'> Social Media Campaign </span>
                    </label>
                  </div>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='skills'
                        type='checkbox'
                        className='register-input ml-2'
                        value="Community Organizer"
                        onChange={handleSkillChange}
                      />
                      <span className='ml-3'> Community Organizer </span>
                    </label>
                  </div>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='skills'
                        type='checkbox'
                        className='register-input ml-2'
                        value="Audio / Video Editing"
                        onChange={handleSkillChange}
                      />
                      <span className='ml-3'> Audio / Video Editing </span>
                    </label>
                  </div>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='skills'
                        type='checkbox'
                        className='register-input ml-2'
                        value="Graphics Designer"
                        onChange={handleSkillChange}
                      />
                      <span className='ml-3'> Graphics Designer </span>
                    </label>
                  </div>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='skills'
                        type='checkbox'
                        className='register-input ml-2'
                        value="Web Designer"
                        onChange={handleSkillChange}
                      />
                      <span className='ml-3'> Web Designer </span>
                    </label>
                  </div>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='skills'
                        type='checkbox'
                        className='register-input ml-2'
                        value="Writer (Literature / Philosophy / Short Story)"
                        onChange={handleSkillChange}
                      />
                      <span className='ml-3'> Writer (Literature / Philosophy / Short Story) </span>
                    </label>
                  </div>
                  <div>
                    <label className='mx-3'>
                      <input
                        id='has_other_skill'
                        type='checkbox'
                        className='register-input ml-2'
                        value={formik.values.has_other_skill}
                        onChange={formik.handleChange}
                      />
                      <span className='ml-3'> Other (Please Specify) </span>
                    </label>
                  </div>

                  {formik.values.has_other_skill &&
                    <input id='other_skills' value={formik.values.other_skills} onChange={formik.handleChange} placeholder='Enter Skill Expertise' className='register-input' />
                  }
                </div>
              </div>

              <div className='flexRow col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12'>
                <div className='flexColumn col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 px-1'>
                  <label className='mb-2'>Region In Which You Can Volunteer / ਤੁਸੀ ਕਿਸ ਜਿਲ੍ਹੇ ਵਿੱਚੋ ਵਲੰਟੀਅਰ ਕਰ ਸਕਦੇ <span className='label_required'> * </span> </label>
                  <input id='volunteer_region' value={formik.values.volunteer_region} onChange={formik.handleChange} placeholder='Region In Which You Can Volunteer' className='register-input'></input>
                </div>
              </div>

              <div>
                <button className="login-button mt-3 mb-2" type='submit'>Register Now</button>
              </div>

            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RegisterForm;
